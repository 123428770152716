<template>
<section class='section'>
  <modal-add-givs
    v-if="showModalAddGivs"
    :close="showModal"
    :addGivs="addGivs"
  />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Banco de $ (Recompensa)</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="control">
            <button
              class="button"
              type="button"
              :class="{ 'is-loading': isLoading }"
              @click="getCsvDownload"
            >
              Descargar
            </button>
          </div>
        </div>
        <div class="level-item">
          <button @click="showModal" class="button button-givu">Agregar Givs</button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline is-centered">
      <div class="column is-4">
        <div class="box">
          <div class="columns is-multiline is-mobile">
            <div class="column is-12 titleBank">
              GivU México
            </div>
            <div class="column is-6 has-text-centered">
              <p class="amount">$ {{Intl.NumberFormat().format(balances.balance_givs || 0)}}</p>
              Saldo $
            </div>
            <div class="column is-6 has-text-centered">
              <p class="amount">{{Intl.NumberFormat().format(balances.balance || 0)}}</p>
              Saldo MXN
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="movementsList"
          :loading=loading
        >
        </base-table>
        <base-pagination
          class="is-medium"
          :page="page"
          :pages="parseInt(pages)"
          :action="paginator"
          :loading="loading"
        />
      </div>
    </div>
</section>
</template>

<script>
import { mapActions } from 'vuex'
const PAYMENT = 1
export default {
  name: 'Bank',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    ModalAddGivs: () => import('@/components/Bank/ModalAddGivs')
  },
  data () {
    return {
      page: 1,
      pages: 1,
      loading: false,
      isLoading: false,
      movementsList: [],
      balances: {},
      showModalAddGivs: false,
      columnsData: [
        { id: 'id', header: '#', class: 'column-mobile' },
        { id: 'typeName', header: 'Tipo de Transacción', class: 'column-mobile' },
        { id: 'transactionTypeName', header: 'Tipo de Movimiento', class: 'column-mobile' },
        {
          id: 'amount',
          header: 'Monto',
          class: 'column-mobile',
          accessor: (row) => `$ ${parseFloat(row.amount).toLocaleString({ style: 'currency', currency: 'USD' })}`
        },
        {
          id: 'comission',
          header: 'Comisión',
          class: 'column-mobile',
          accessor: (row) => (row.typeId === PAYMENT && !row.is_admin) ? `G ${parseFloat(row.comission).toLocaleString({ style: 'currency', currency: 'USD' })}` : 'N/A'
        },
        { id: 'adminName', header: 'Usuario Admin', class: 'column-mobile' }
      ]
    }
  },
  methods: {
    ...mapActions(['getBankMovements', 'getBankBalances', 'incrementeGivsBalance', 'csvDownloadBank', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.getBankMovements({ page: this.page, search: null })
        .then(response => {
          this.pages = response.totalPages
          this.movementsList = response.payload
        })
    },
    showModal () {
      this.showModalAddGivs = !this.showModalAddGivs
    },
    addGivs (amount) {
      this.incrementeGivsBalance({ amount, accountId: 1 }) // @TODO: se manda solo la cuenta para México
        .then(response => {
          this.getBankBalances()
            .then(response => {
              this.balances = response.payload
            })
          this.getBankMovements({ page: this.page, search: null })
            .then(response => {
              this.pages = response.totalPages
              this.movementsList = response.payload
            })
          this.showModal()
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadBank()
        .then(resp => {
          if (resp) {
            const exportName = 'Export bank.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.getBankBalances()
      .then(response => {
        this.balances = response.payload
      })
    this.getBankMovements({ page: this.page, search: null })
      .then(response => {
        this.pages = response.totalPages
        this.movementsList = response.payload
      })
  }
}
</script>

<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .titleBank {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  .amount {
    font-size: 30px;
    font-weight: bolder;
    color: var(--secondary-color)
  }
</style>
